export const companyCertificates = [
    {
        id: 1,
        name: 'ISO 14001',
        url: 'https://www.flugger.com/wp-content/uploads/2024/12/DK015171-1-Flugger-group-AS-ISO-14001-UKAS-UK-25-02-2022.pdf',
    },
    {
        id: 2,
        name: 'ISO 9001',
        url: 'https://www.flugger.com/wp-content/uploads/2024/12/DK015170-1-Flugger-Group-AS-ISO-9001-UKAS-UK-24-02-22.pdf',
    },
    {
        id: 3,
        name: 'Nordic Ecolabel license, Paint & varnish',
        url: 'https://www.flugger.com/wp-content/uploads/2024/12/3096-0024-3-Flugger-Group-AS-096-Paints-and-varnishesSverige_Certifikat_English.pdf',
    },
    {
        id: 4,
        name: 'Nordic Ecolabel license, Filler and adhesive',
        url: 'https://www.flugger.com/wp-content/uploads/2024/12/3097-0008-2.68-Flugger-Group-AS-Sverige_Certifikat_English.pdf',
    },
    {
        id: 5,
        name: 'Nordic Ecolabel license, Exterior products',
        url: 'https://www.flugger.com/wp-content/uploads/2024/12/3097-0029-2.70-Flugger-Group-AS-Sverige_Certifikat_English_chemical-building-products.pdf',
    },
    {
        id: 6,
        name: 'EU Ecolabel license',
        url: 'https://www.flugger.com/wp-content/uploads/2024/12/EU-Ecolabel-Iicense-Certificate-Flugger_Oct-2018.pdf',
    },
];
